export const APP_TITLE = "Integrated Operations Management System";
// export const BASE_URL = "https://api.iomscct.com/api/v1";
export const BASE_URL = "https://api-ioms-staging.kridatech.com/api/v1";
// export const BASE_URL = "http://localhost:8000/api/v1";
// export const BASE_URL = "http://127.0.0.1:8000/api/v1";
export const assetCategories = {
  1: "Tanah",
  2: "Gedung dan Bangunan",
  3: "Jalan, Irigasi, dan Jaringan",
  4: "Mesin dan Peralatan",
  5: "Aset Tak Berwujud",
};
export const assetSubCategories = {
  1: "Perkerasan Jalur Utama",
  2: "Drainase",
  3: "Median",
  4: "Bahu Jalan",
  5: "Rounding",
  6: "Petunjuk Jalan",
  7: "Fasilitas Lainnya",
  8: "Kebersihan",
  9: "Tanaman",
};
export const assetAdvanceCategories = {
  1: "Kekesatan",
  2: "Ketidakrataan",
  3: "Tidak Ada Lubang",
  4: "Rutting",
  5: "Retak",
  6: "Tidak Ada Endapan",
  7: "Penampang Saluran",
  8: "Kerb",
  9: "Median Concrete Barrier (MCB)",
  10: "Guard Rail",
  11: "Wire Rope",
  12: "Tidak Ada Lubang",
  13: "Rutting",
  14: "Retak",
  15: "Perambuan",
  16: "Marka Jalan",
  17: "Guide Post",
  18: "Patok Kilo / Hektometer",
  19: "PJU",
  20: "Anti Silau",
  21: "Pagar Rumija",
  22: "Pagar Pengaman",
  23: "Dalam Rumija Tol",
  24: "Kantor Operasi dan Gardu Tol",
};
export const lanes = {
  1: "Bahu Dalam",
  2: "Bahu Luar",
  3: "Lajur 1",
  4: "Lajur 2",
  5: "Lajur 3",
};
export const tracks = {
  1: "Jalur A",
  2: "Jalur B",
};
export const deadlines = {
  1: 2 * 24,
  2: 7 * 24,
  3: 7 * 24,
  4: 2 * 24,
  5: 7 * 24,
  9: 2 * 24,
  15: 3 * 24,
  16: 14 * 24,
  17: 3 * 24,
  18: 7 * 24,
  19: 2 * 24,
  20: 2 * 24,
  21: 2 * 24,
  22: 7 * 24,
  23: 7 * 24,
  24: 2 * 24,
};
export const sources = {
  DEFAULT: "Laporan Inisiasi",
  ACCIDENT: "Kecelakaan",
  ASSET_DISRUPTION: "Gangguan Aset",
  TRAFFIC_DISTURBANCE: "Gangguan Lalu Lintas",
  SPECIAL_ACCIDENT: "Kejadian Khusus",
};
export const categoryToUrl = {
  1: "tanah",
  2: "gedung-bangunan",
  3: "jalan-irigasi-jaringan",
  4: "mesin-peralatan",
  5: "aset-tak-berwujud",
};

export const SCOPES = {
  view_asset: "WEB_ASSET_VIEWER",
  manage_asset: "WEB_ASSET_MANAGER",
  delete_asset: "WEB_ASSET_DELETER",
  view_transaction: "WEB_TRANSACTION_VIEWER",
  manage_transaction: "WEB_TRANSACTION_MANAGER",
  maintenance: "WEB_MAINTENANCE",
  accident: "WEB_ACCIDENT",
  asset_disruption: "WEB_ASSET_DISRUPTION",
  special_incident: "WEB_SPECIAL_INCIDENT",
  traffic_disturbance: "WEB_TRAFFIC_DISTURBANCE",
  super: "SUPER_ADMIN",
};

export const segments = [
  {
    name: "Cimanggis - Jatikarya",
    length: 2.75,
    value: "1",
  },
  {
    name: "Jatikarya - Nagrak",
    length: 3.78,
    value: "2",
  },
  {
    name: "Nagrak - Narogong",
    length: 3.07,
    value: "3",
  },
  {
    name: "Narogong - Burangkeng",
    length: 6.36,
    value: "4",
  },
  {
    name: "Burangkeng - Setu Utara",
    length: 7.86,
    value: "5",
  },
  {
    name: "Setu Utara - Cibitung Junction",
    length: 2.37,
    value: "6",
  },
];

export const groups = [
  { title: "Golongan 1", value: "1" },
  { title: "Golongan 2", value: "2" },
  { title: "Golongan 3", value: "3" },
  { title: "Golongan 4", value: "4" },
  { title: "Golongan 5", value: "5" },
]

export const periods = [
  { title: "Harian", value: "daily" },
  { title: "Mingguan", value: "weekly" },
  { title: "Bulanan", value: "monthly" },
]

export const payments = [
  { title: "BCA", value: "BCA" },
  { title: "Mandiri", value: "MDR" },
  { title: "BRI", value: "BRI" },
  { title: "BNI", value: "BNI" },
  { title: "Tunai", value: "CASH" },
]