<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import DateFilterComponent from "@/components/LayananTransaksi/DateFilterComponent.vue";
import ReportSummaryComponent from "@/components/LayananTransaksi/LHRPersegmen/ReportSummaryComponent.vue";
import RevenueGraphComponent from "@/components/LayananTransaksi/LHRPersegmen/RevenueGraphComponent.vue";
import TableStyleComponentTotal from "@/components/LayananTransaksi/LHRPersegmen/TableStyleComponentTotal.vue";
import TableStyleComponentDetail from "@/components/LayananTransaksi/LHRPersegmen/TableStyleComponentDetail.vue";

export default {
  components: {
    LoadingComponent,
    DateFilterComponent,
    ReportSummaryComponent,
    RevenueGraphComponent,
    TableStyleComponentTotal,
    TableStyleComponentDetail,
  },
  data() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
    return {
      start_date: firstDayOfMonth.toISOString().split("T")[0],
      end_date: today.toISOString().split("T")[0],
      formatted_date_range: "",
      selectedPeriod: "daily",
      selectedGate: ["1", "2", "3", "4", "5", "6"],
      selectedGolongan: ["1", "2", "3", "4", "5"],
      reportData: null,
      fetchDebounceTimeout: null,
      //   initialLoad: true,
    };
  },
  computed: {
    formattedGate() {
      return this.selectedGate.join(",");
    },
    formattedGolongan() {
      return this.selectedGolongan.join(",");
    },
    fetchParams() {
      return {
        from: this.start_date,
        to: this.end_date,
        groups: "",
        segment_ids: this.formattedGate,
        graph_type: this.selectedPeriod,
      };
    },
  },
  watch: {
    fetchParams: {
      handler() {
        if (
          this.start_date &&
          this.end_date &&
          this.selectedPeriod &&
          this.selectedGate.length > 0
        ) {
          this.debouncedFetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    updateStartDate(newDate) {
      if (this.start_date !== newDate) {
        this.start_date = newDate;
        // this.initialLoad = false;
      }
    },
    updateEndDate(newDate) {
      if (this.end_date !== newDate) {
        this.end_date = newDate;
        // this.initialLoad = false;
      }
    },
    updateFormattedDateRange(newRange) {
      this.formatted_date_range = newRange;
    },
    updatePeriod(newPeriod) {
      if (this.selectedPeriod !== newPeriod) {
        this.selectedPeriod = newPeriod;
        // this.initialLoad = false;
      }
    },
    updateGate(newGate) {
      if (JSON.stringify(this.selectedGate) !== JSON.stringify(newGate)) {
        this.selectedGate = newGate;
        // this.initialLoad = false;
      }
    },
    updateGolongan(newGolongan) {
      this.selectedGolongan = newGolongan;
    },
    debouncedFetchData() {
      clearTimeout(this.fetchDebounceTimeout);
      this.fetchDebounceTimeout = setTimeout(() => {
        this.fetchData();
      }, 1500);
    },
    async fetchData() {
      try {
        const response = await this.$axios.get("lhr-per-segments", {
          params: this.fetchParams,
        });
        this.reportData = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  beforeDestroy() {
    clearTimeout(this.fetchDebounceTimeout);
  },
};
</script>

<template>
  <div class="pa-0 ma-0">
    <!-- Loading state -->
    <v-row
      v-if="!reportData"
      justify="center"
      align="center"
      style="height: 100vh"
    >
      <LoadingComponent />
    </v-row>

    <!-- Content -->
    <div v-else>
      <div class="pb-3 pt-4 px-10">
        <v-row no-gutters>
          <v-col align="left" class="my-auto" cols="7">
            <v-sheet>
              <h3 class="text--black" style="font-size: 20px; font-weight: 500">
                {{ formatted_date_range }}
              </h3>
            </v-sheet>
          </v-col>
          <v-col align="right" class="my-auto" cols="5">
            <v-sheet>
              <v-row no-gutters>
                <v-col align="left" class="my-auto" cols="2">
                  <v-sheet>
                    <span
                      class="text--black text-uppercase"
                      style="font-size: 12px; font-weight: 400; color: #bbbbbb"
                      >Filter</span
                    >
                    <h6
                      class="text--black"
                      style="font-size: 14px; font-weight: 400"
                    >
                      Pilih Tanggal
                    </h6>
                  </v-sheet>
                </v-col>
                <v-col align="right" class="my-auto" cols="10">
                  <v-sheet>
                    <DateFilterComponent
                      @update:startDate="updateStartDate"
                      @update:endDate="updateEndDate"
                      @update:formattedDateRange="updateFormattedDateRange"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <ReportSummaryComponent :data="reportData.summary" />
      <RevenueGraphComponent
        :data="reportData.graph"
        @update:selectedPeriod="updatePeriod"
        @update:selectedGate="updateGate"
        @update:selectedGolongan="updateGolongan"
      />
      <div class="pb-3 pt-4 px-10">
        <div class="mb-5 rounded-xl py-4 px-6 text-center bordered">
          <h3 style="font-size: 18px; font-weight: 500" class="text-uppercase">
            Detail
          </h3>
        </div>
        <div class="py-5">
          <v-row class="mb-4">
            <v-col cols="9" class="my-auto">
              <h3 class="text--black" style="font-size: 20px; font-weight: 500">
                {{ formatted_date_range }}
              </h3>
            </v-col>
          </v-row>
          <TableStyleComponentTotal :data="reportData.details.sum_all_gates" />
          <div>
            <template v-for="(item, index) in reportData.details.segment_list">
              <div :key="item.segment">
                <TableStyleComponentDetail
                  :data="item"
                  :isEven="index % 2 === 0"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bordered {
  background-color: #dae7ff;
  border-left: 4px solid #497bdd;
  border-right: 4px solid #497bdd;
}
</style>
